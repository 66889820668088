import moment from "moment"

export const doctorTextMessageTemplate = () => {
  return `[${moment().format(
    "YYYY/MM/DD hh:mm:ss A"
  )}] Welcome to the CarePlus Program. Your enrollment has been APPROVED. Check your email for details on how to get started. For inquiries, you may contact us at 0917 866 9487. Thank you.`
}

export const patientTextMessageTemplate = () => {
  return `[${moment().format(
    "YYYY/MM/DD hh:mm:ss A"
  )}] CarePlus: Your enrollment has been received. Our coordinator will update you within the day during operating hours (Mon - Fri, 8:30AM-4:30PM) or next working day for any further updates. If you do not hear from us within one working day, please reach out to us through SMS or email. Thank you!`
}
