import axios from "axios"
import { isTest } from "./isTest"

const EMAIL_URL = process.env.GATSBY_SEND_EMAIL_API_URL
const API_KEY = process.env.GATSBY_SEND_EMAIL_API_KEY

/*
 * Send email service. Send email to submitter, cc maerskcare@medgrocer.com
 */

export async function sendEmail({
  to,
  cc,
  from,
  subject,
  html,
  callback = () => null,
  attachments,
  bcc,
}) {
  const emailBody = { to, from, subject, html, attachments }
  if (cc) emailBody["cc"] = cc
  if (bcc) emailBody["bcc"] = bcc
  if (isTest()) emailBody["subject"] = `${subject}`
  const response = await axios
    .post(EMAIL_URL, emailBody, {
      headers: { "x-api-key": API_KEY },
    })
    .then(() => {
      callback()
    })
    .catch((error) =>
      console.log("There was an error sending to ", to, ": ", error)
    )
  return response
}
